import { ExperimentGroups } from "./group";

export enum FeatureStatus {
  ACTIVE = "Aktiv",
  INACTIVE = "Inaktiv",
  EXPERIMENT_GROUP_A = "A",
  EXPERIMENT_GROUP_B = "B",
  PREVIEW = "Preview",
  PREVIEW_GROUP_A = "Preview A",
  PREVIEW_GROUP_B = "Preview B",
}

export type FeatureStatuses = {
  vippsCheckout: FeatureStatus;
};

export const isFeatureEnabled = (
  fs: FeatureStatus | null,
  experimentGroup: ExperimentGroups,
) => {
  if (
    fs === FeatureStatus.ACTIVE ||
    (fs === FeatureStatus.EXPERIMENT_GROUP_A &&
      experimentGroup === ExperimentGroups.A) ||
    (fs === FeatureStatus.EXPERIMENT_GROUP_B &&
      experimentGroup === ExperimentGroups.B)
  ) {
    return true;
  }

  if (process.env.VERCEL_ENV === "preview") {
    if (
      fs === FeatureStatus.PREVIEW ||
      (fs === FeatureStatus.PREVIEW_GROUP_A &&
        experimentGroup === ExperimentGroups.A) ||
      (fs === FeatureStatus.PREVIEW_GROUP_B &&
        experimentGroup === ExperimentGroups.B)
    ) {
      return true;
    }
  }

  return false;
};
